<template>
  <div class="gg-container" style="position: relative;">
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >订单详情</span
      >
      <div class="clm" style="line-height:3;padding:20px 0">
        <div>
          <span>订单号：{{ info.order.order_sn }}</span>
          <span style="margin-left:15%;"
            >用户ID：{{ info.order.member_id }}</span
          >
        </div>
        <div>
          <span>创建时间：{{ info.order.created_at }}</span>
          <span style="margin:0 15%;">关联订单：{{ info.order.sale_sn }}</span>
          <span>订单状态：{{ info.order.status_name }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >申请信息</span
      >
      <div class="clm" style="line-height:3;padding:20px 0">
        <div>
          <span>售后类型：{{ info.apply.type_name }}</span>
          <span style="margin-left:15%;"
            >退款原因：{{ info.apply.reason }}</span
          >
        </div>
        <div>
          <span>其他说明：{{ info.apply.other_desc }}</span>
        </div>
        <div class="row" style="align-items:self-start;">
          <span>凭证：</span
          ><img
            :src="info.apply.images"
            style="width:180px;height:100px"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >售后商品</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.goods"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="goods_name"
            label="商品名称"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="goods_price"
            label="销售价"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="number"
            label="购买数量"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="total_money"
            label="小计"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="discount_amount"
            label="优惠金额"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
        <div
          style="display: flex;justify-content: flex-end;width:1150px;margin-top:20px;font-size:18px"
        >
          合计：{{ info.goods_total_money }}
        </div>
      </div>
    </div>
    <div class="content">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >物流信息</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.express"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="express_company"
            label="快递公司"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="express_no"
            label="快递单号"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="发货时间"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content" v-if="info.apply.type_name == '退货退款'">
      <span style="font-size:20px;border-bottom:3px solid #1684FC;"
        >售后日志</span
      >
      <div style="margin-top:30px">
        <el-table
          :data="info.logs"
          style="width:1200px;text-align: center;"
          :header-cell-style="{ 'text-align': 'center' }"
          border
          size="mini"
        >
          <el-table-column
            prop="comment"
            label="操作类型"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="admin_type_name"
            label="操作人类型"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="username"
            label="操作人"
            align="center"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            prop="created_at"
            label="操作时间"
            align="center"
            width="auto"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="row" style="justify-content: flex-end;">
      <el-button
        type="danger"
        size="mini"
        v-if="info.order.status_name == '待审核' && !info.express[0].express_no"
        @click="handlePass(2, id)"
        >驳回</el-button
      >
      <el-button
        type="primary"
        size="mini"
        v-if="info.order.status_name == '待审核' && !info.express[0].express_no"
        @click="handlePass(1, id)"
        >通过</el-button
      >
      <el-button
        type="primary"
        size="mini"
        v-if="info.order.status_name == '待审核' && info.express[0].express_no"
        @click="handleTwo(id)"
        >退货退款审核</el-button
      >
      <el-button
        size="mini"
        @click="goBack()"
        v-if="
          info.order.status_name == '已完成' && info.apply.type_name == '仅退款'
        "
        >取消</el-button
      >
      <el-button
        type="primary"
        size="mini"
        v-if="
          info.order.status_name == '通过' && info.apply.type_name == '退货退款'
        "
        >确认收货</el-button
      >
    </div>
    <el-dialog :visible.sync="diagLog" width="30%">
      <span style="border-bottom:2px solid #2362FB" slot="title">{{
        status_type == 1 ? "退款金额" : "驳回原因"
      }}</span>
      <el-input
        v-if="status_type == 2"
        v-model="reject_reason"
        type="textarea"
        size="mini"
        :rows="6"
        placeholder="请填写驳回原因"
      ></el-input>
      <div v-if="status_type == 1">
        <el-input
          v-model="real_refund_amount"
          type=""
          style="width: 200px !important;"
          size="mini"
          placeholder="请输入退款金额"
        ></el-input>
        <span style="margin-left:20px"
          >当前订单退款总金额:{{ info.goods_total_money }}</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diagLog = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleCon" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="twoDialogVisible" width="30%">
      <span style="border-bottom:2px solid #2362FB" slot="title">二次审核</span>
      <el-radio-group
        v-model="status"
        @change="changeRadio"
        style="margin:10px 0;"
      >
        <el-radio :label="1">通过</el-radio>
        <el-radio :label="2">拒绝</el-radio>
      </el-radio-group>
      <el-input
        v-if="status_type == 2"
        v-model="reject_reason"
        type="textarea"
        size="mini"
        :rows="6"
        placeholder="请填写驳回原因"
      ></el-input>
      <div v-if="status_type == 1">
        <el-input
          v-model="real_refund_amount"
          type=""
          style="width: 200px !important;"
          size="mini"
          placeholder="请输入退款金额"
        ></el-input>
        <span style="margin-left:20px"
          >当前订单退款总金额:{{ info.goods_total_money }}</span
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="twoDialogVisible = false" size="mini"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleConTwo" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAfterInfo, postExamine, postExamineRefund } from "@/newApi/order";
export default {
  name: "OrderDetail",
  created() {
    console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    var params = {
      id: this.$route.query.id,
    };
    this._getOrderDetail(params);
    // this._getAdminExpressInfo(params.id);
  },
  data() {
    return {
      status: null,
      twoDialogVisible: false,
      diagLog: false,
      id: "",
      reject_reason: "",
      info: {},
      peopleInfo: {},
      originOrder: this.$route.query.originOrder || "customer",
      listLoading: false,
      dialogVisible: false,
      tableDataBasicInfo: [],
      tableDataPersonInfo: [],
      tableDataGoodInfo: [],
      tableDataMoneyInfo: [],
      tableDataOptionInfo: [],
      activities: [], // 物流时间线
      expressInfo: [], // 物流信息
      expressCompany: "", // 快递公司
      expressNo: "", // 快递单号
      sync_status: ["无需同步", "待同步", "已同步", "同步失败"], //订单同步状态:0-无需同步(内部订单),1-待同步,2-已同步,3-同步失败
      order_type: ["", "处方订单", "商城订单", "客情订单", "三方商城订单"], // 订单类型(1=处方订单  2 商城订单  3 客情订单 4 三方商城订单）
      activeStep: 0,
      status_type: null,
      real_refund_amount: null,
    };
  },
  methods: {
    changeRadio(e) {
      console.log(e,1111);
      this.status_type = e;
      this.real_refund_amount = this.info.goods_total_money;
    },
    handleConTwo(e) {
      console.log(e);
      if (this.status_type == 1) {
        if (this.real_refund_amount == "") {
          this.$message({
            type: "error",
            message: "请输入退款金额",
          });
          return false;
        }
        if (this.real_refund_amount > this.info.goods_total_money) {
          this.$message({
            type: "error",
            message: "退款金额不能大于订单实付金额",
          });
          return false;
        }
        postExamineRefund({
          id: this.id,
          status: 1,
          real_refund_amount: this.real_refund_amount,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "审核成功",
              });
              this.twoDialogVisible = false;
              this.real_refund_amount = "";
              var params = {
                id: this.$route.query.id,
              };
              this._getOrderDetail(params);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      } else {
        if (this.reject_reason == "") {
          this.$message({
            type: "error",
            message: "请输入驳回原因",
          });
          return false;
        }
        postExamineRefund({
          id: this.id,
          status: 2,
          reject_reason: this.reject_reason,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "驳回成功",
              });
              this.twoDialogVisible = false;
              this.reject_reason = "";
              var params = {
                id: this.$route.query.id,
              };
              this._getOrderDetail(params);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      }
    },
    handleTwo(id) {
      console.log(id);
      this.twoDialogVisible = true;
    },
    handleCon() {
      if (this.status_type == 2) {
        if (this.reject_reason == "") {
          this.$message({
            type: "error",
            message: "请输入驳回原因",
          });
          return false;
        }
        postExamine({
          id: this.id,
          status: 2,
          reject_reason: this.reject_reason,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "驳回成功",
              });
              this.diagLog = false;
              this.reject_reason = "";
              var params = {
                id: this.$route.query.id,
              };
              this._getOrderDetail(params);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      } else {
        if (this.real_refund_amount == "") {
          this.$message({
            type: "error",
            message: "请输入退款金额",
          });
          return false;
        }
        if (this.real_refund_amount > this.info.goods_total_money) {
          this.$message({
            type: "error",
            message: "退款金额不能大于订单实付金额",
          });
          return false;
        }
        postExamine({
          id: this.id,
          status: 1,
          real_refund_amount: this.real_refund_amount,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "审核成功",
              });
              this.diagLog = false;
              this.real_refund_amount = "";
              var params = {
                id: this.$route.query.id,
              };
              this._getOrderDetail(params);
            }
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error.message,
            });
          });
      }
    },
    handlePass(row, id) {
      this.status_type = row;
      this.real_refund_amount = this.info.goods_total_money;
      console.log(this.info.apply.type_name == "退货退款");
      if (row == 1) {
        if (this.info.apply.type_name == "退货退款") {
          this.$confirm("是否通过审核？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              postExamine({ id: id, status: 1 })
                .then((res) => {
                  if (res.code == 200) {
                    this.$message({
                      type: "success",
                      message: "审核成功",
                    });
                    var params = {
                      id: this.$route.query.id,
                    };
                    this.diagLog = false;
                    this._getOrderDetail(params);
                  }
                })
                .catch((error) => {
                  this.$message({
                    type: "error",
                    message: error.message,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        } else {
          this.diagLog = true;
        }
      } else {
        this.diagLog = true;
      }
    },
    //返回上一级
    goBack() {
      this.$router.go(-1);
    },
    _getOrderDetail(params) {
      this.listLoading = true;
      getAfterInfo(params)
        .then((response) => {
          if (response.code == 200) {
            console.log("");
            var _data = response.data;
            this.info = _data;
          } else {
            this.$message({
              type: "error",
              message: response.msg,
            });
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    handleShoppingDetail() {
      console.log(111);
      this.dialogVisible = true;
    },
    handleCloseLogisticsInfo() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.row {
  display: flex;
}
.clm {
  display: flex;
  flex-direction: column;
}
.jsb {
  justify-content: space-between;
}
.alc {
  align-items: center;
}
.content {
  margin-bottom: 20px;
  padding: 10px 0;
}
</style>
